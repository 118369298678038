import {
  FETCH_FEATURE_REQUESTS,
  SEARCH_FEATURE_REQUESTS,
  ADD_FEATURE_REQUEST,
  ADD_FEATURE_REQUEST_COMMENT,
  CLEAR_EDIT_FEATURE_REQUEST_REDIRECT,
  CLEAR_EDIT_FEATURE_REQUEST_SENT,
  DELETE_FEATURE_REQUEST_COMMENT,
  EDIT_FEATURE_REQUEST_COMMENT,
  SHOW_ADD_FEATURE_REQUEST_COMMENT,
  UPDATE_FEATURE_REQUEST,
  UPDATE_FEATURE_REQUEST_COMMENT,
  VOTE_FOR_FEATURE_REQUEST,
  FETCH_FEATURE_REQUEST_STATUSES,
  FETCH_FEATURE_REQUEST_PRITOITIES
} from "./actions";

interface FeatureRequestState {
  summmaryFeatureRequests: any[];
  featureRequests: any[];
  featureRequestsLoading: boolean;
  featureRequestSaving: boolean;
  featureRequestCommentSaving: boolean;
  featureRequestSent: boolean;
  featureRequestErrors: any;
  featureRequest: any;
  emptyFeatureRequest: any;
  loading: boolean;
  errors: any;
  voteForLoading: boolean;
  redirect: string | null;
  showAddComment: boolean;
  statuses: any[];
  priorities: any[];
  prioritiesLoading: boolean;
}

const featureRequestState: FeatureRequestState = {
  summmaryFeatureRequests: [],
  featureRequests: [],
  featureRequestSaving: false,
  featureRequestCommentSaving: false,
  featureRequestsLoading: false,
  featureRequestSent: false,
  featureRequestErrors: false,
  featureRequest: null,
  emptyFeatureRequest: { allowShow: true, status: 0, priority: 1 },
  loading: false,
  errors: null,
  voteForLoading: false,
  redirect: null,
  showAddComment: false,
  statuses: [],
  priorities: [],
  prioritiesLoading: true
}

const reducer = (state = featureRequestState, action: any = {}) => {
  switch (action.type) {
    case FETCH_FEATURE_REQUESTS + "_PENDING": {
      return {
        ...state,
        featureRequestsLoading: true
      };
    }

    case FETCH_FEATURE_REQUESTS + "_REJECTED": {
      return {
        ...state,
        featureRequestsLoading: false,
        errors: action.payload.response.data.errors
      };
    }

    case FETCH_FEATURE_REQUESTS + "_FULFILLED": {
      return {
        ...state,
        featureRequestsLoading: false,
        summmaryFeatureRequests: action.payload.data.featureRequests
      };
    }

    case SEARCH_FEATURE_REQUESTS + "_PENDING": {
      return {
        ...state,
        featureRequestsLoading: true
      };
    }

    case SEARCH_FEATURE_REQUESTS + "_REJECTED": {
      return {
        ...state,
        featureRequestsLoading: false,
        errors: action.payload.response.data.errors
      };
    }

    case SEARCH_FEATURE_REQUESTS + "_FULFILLED": {
      return {
        ...state,
        featureRequestsLoading: false,
        featureRequests: action.payload.data.featureRequests
      };
    }

    case "FETCH_FEATURE_REQUEST_VIEW_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case "FETCH_FEATURE_REQUEST_VIEW_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.response.data.errors
      };
    }

    case "FETCH_FEATURE_REQUEST_VIEW_FULFILLED": {
      return {
        ...state,
        loading: false,
        featureRequest: action.payload.data
      };
    }

    case "FETCH_FEATURE_REQUEST_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case "FETCH_FEATURE_REQUEST_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: action.payload.response.data.errors
      };
    }

    case "FETCH_FEATURE_REQUEST_FULFILLED": {
      return {
        ...state,
        loading: false,
        featureRequest: action.payload.data
      };
    }

    case ADD_FEATURE_REQUEST + "_PENDING": {
      return {
        ...state,
        featureRequestLoading: true,
        featureRequestErrors: null,
        featureRequestSent: false
      };
    }

    case ADD_FEATURE_REQUEST + "_FULFILLED": {
      const newSummaryFeatureRequests: any[] = Array.from(state.summmaryFeatureRequests);
      const newFeatureRequests: any[] = Array.from(state.featureRequests);
      if (action.meta.featureRequest.allowShow) {
        newSummaryFeatureRequests.unshift(action.payload.data.featureRequest);
        newFeatureRequests.unshift(action.payload.data.featureRequest);
      }

      const redirect = action.meta.standAlone ? { to: "list" } : null;

      return {
        ...state,
        featureRequestLoading: false,
        featureRequestErrors: null,
        featureRequestSent: true,
        emptyFeatureRequest: { allowShow: true, status: 0 },
        summmaryFeatureRequests: newSummaryFeatureRequests,
        featureRequests: newFeatureRequests,
        redirect
      };
    }

    case ADD_FEATURE_REQUEST + "_REJECTED": {
      return {
        ...state,
        featureRequestErrors: action.payload.response.data.errors,
        featureRequestLoading: false
      };
    }

    case FETCH_FEATURE_REQUEST_STATUSES + "_PENDING": {
      return {
        ...state,
        featureRequestLoading: true
      };
    }

    case FETCH_FEATURE_REQUEST_STATUSES + "_FULFILLED": {
      return {
        ...state,
        featureRequestLoading: false,
        statuses: action.payload.data
      };
    }

    case FETCH_FEATURE_REQUEST_STATUSES + "_REJECTED": {
      return {
        ...state,
        featureRequestErrors: action.payload.response.data.errors,
        featureRequestLoading: false
      };
    }

    case FETCH_FEATURE_REQUEST_PRITOITIES + "_PENDING": {
      return {
        ...state,
        prioritiesLoading: true
      };
    }

    case FETCH_FEATURE_REQUEST_PRITOITIES + "_FULFILLED": {
      return {
        ...state,
        prioritiesLoading: false,
        priorities: action.payload.data
      };
    }

    case FETCH_FEATURE_REQUEST_PRITOITIES + "_REJECTED": {
      return {
        ...state,
        featureRequestErrors: action.payload.response.data.errors,
        prioritiesLoading: false
      };
    }

    case UPDATE_FEATURE_REQUEST + "_PENDING": {
      return {
        ...state,
        featureRequestLoading: true,
        featureRequestErrors: null
      };
    }

    case UPDATE_FEATURE_REQUEST + "_FULFILLED": {
      const updateFeatureRequest = action.payload.data.featureRequest
      const featureRequests = Array.from(state.featureRequests);
      const index = featureRequests.findIndex(r => r.id === updateFeatureRequest.id);
      if (index > -1) {
        featureRequests.splice(index, 1, updateFeatureRequest);
      }

      return {
        ...state,
        featureRequestLoading: false,
        featureRequestErrors: null,
        featureRequests,
        redirect: { to: "view" }
      };
    }

    case UPDATE_FEATURE_REQUEST + "_REJECTED": {
      return {
        ...state,
        featureRequestErrors: action.payload.response.data.errors,
        featureRequestLoading: false
      };
    }

    case VOTE_FOR_FEATURE_REQUEST + "_PENDING": {
      return {
        ...state,
        voteForLoading: true
      };
    }

    case VOTE_FOR_FEATURE_REQUEST + "_FULFILLED": {
      const featureRequest = { ...state.featureRequest };

      if (action.meta.vote) {
        featureRequest.totalVotes++;
        featureRequest.canUpVote = false;
        featureRequest.canDownVote = true;
      } else {
        featureRequest.totalVotes--;
        featureRequest.canUpVote = true;
        featureRequest.canDownVote = false;
      }

      return {
        ...state,
        featureRequest: featureRequest,
        voteForLoading: false
      };
    }

    case VOTE_FOR_FEATURE_REQUEST + "_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        voteForLoading: false
      };
    }

    case ADD_FEATURE_REQUEST_COMMENT + "_PENDING": {
      return {
        ...state,
        featureRequestCommentSaving: true,
        featureRequestErrors: null
      };
    }

    case ADD_FEATURE_REQUEST_COMMENT + "_FULFILLED": {
      const newFeatureRequest = { ...state.featureRequest };
      newFeatureRequest.comments.push(action.payload.data.featureRequestComment)

      return {
        ...state,
        featureRequestCommentSaving: false,
        featureRequestErrors: null,
        featureRequest: newFeatureRequest,
        showAddComment: false
      };
    }

    case ADD_FEATURE_REQUEST_COMMENT + "_REJECTED": {
      return {
        ...state,
        featureRequestErrors: action.payload.response.data.errors,
        featureRequestCommentSaving: false
      };
    }

    case UPDATE_FEATURE_REQUEST_COMMENT + "_PENDING": {
      return {
        ...state,
        featureRequestCommentSaving: true,
        featureRequestErrors: null
      };
    }

    case UPDATE_FEATURE_REQUEST_COMMENT + "_FULFILLED": {
      const updatedFeatureRequest = { ...state.featureRequest };
      const returnedComment = action.payload.data.featureRequestComment;
      returnedComment.editMode = false;
      const index = updatedFeatureRequest.comments.findIndex((comment: any) => comment.id === returnedComment.id);
      updatedFeatureRequest.comments[index] = returnedComment;

      return {
        ...state,
        featureRequestCommentSaving: false,
        featureRequestErrors: null,
        featureRequest: updatedFeatureRequest,
        showAddComment: false
      };
    }

    case UPDATE_FEATURE_REQUEST_COMMENT + "_REJECTED": {
      return {
        ...state,
        featureRequestErrors: action.payload.response.data.errors,
        featureRequestCommentSaving: false
      };
    }

    case DELETE_FEATURE_REQUEST_COMMENT + "_PENDING": {
      return {
        ...state,
        featureRequestLoading: true,
        featureRequestErrors: null
      };
    }

    case DELETE_FEATURE_REQUEST_COMMENT + "_FULFILLED": {
      const updatedFeatureRequest = { ...state.featureRequest };
      const featureRequestCommentId = action.meta.featureRequestCommentId;
      const index = updatedFeatureRequest.comments.findIndex((comment: any) => comment.id === featureRequestCommentId);
      updatedFeatureRequest.comments.splice(index, 1);

      return {
        ...state,
        featureRequest: updatedFeatureRequest,
        featureRequestLoading: false,
        featureRequestErrors: null
      };
    }

    case DELETE_FEATURE_REQUEST_COMMENT + "_REJECTED": {
      return {
        ...state,
        featureRequestErrors: action.payload.response.data.errors,
        featureRequestLoading: false
      };
    }

    case EDIT_FEATURE_REQUEST_COMMENT: {
      const editMode = action.meta.editMode;
      const commentId = action.meta.commentId;

      const updatedFeatureRequest = { ...state.featureRequest };
      const comment = updatedFeatureRequest.comments.find((comment: any) => comment.id === commentId);
      comment.editMode = editMode;

      return {
        ...state,
        featureRequest: updatedFeatureRequest
      };
    }

    case SHOW_ADD_FEATURE_REQUEST_COMMENT: {
      const show = action.meta.show;
      return {
        ...state,
        showAddComment: show
      };
    }

    case CLEAR_EDIT_FEATURE_REQUEST_REDIRECT: {
      return {
        ...state,
        redirect: null
      };
    }

    case CLEAR_EDIT_FEATURE_REQUEST_SENT: {
      return {
        ...state,
        featureRequestSent: false,
        //featureRequests: []
      };
    }

    default:
      return state;
  }
}

export default reducer;