import { Link } from "react-router-dom";
import CkEditorContent from "../../../shared/controls/ckeditor-content";

function ListFeatureRequestsRow(props) {
  const {
    featureRequest
  } = props;

  const description = featureRequest.description + (featureRequest.description?.length === 50 ? "..." : "");

  return (

    <tr>
      <td className="feature-request-list-table-title">
        <Link className="" to={`/feature-request/${featureRequest.id}`}>{featureRequest.title}</Link>
        <div className="feature-request-list-table-description">
          <CkEditorContent content={description} />
        </div>
      </td>
      <td className="feature-request-line-votes">
        <i className="material-icons" title="Votes">thumb_up</i>&nbsp;{featureRequest.totalVotes}&nbsp;&nbsp;&nbsp;
      </td>
      <td className="feature-request-line-votes">
        <i className="material-icons feature-request-comment-icon" title="Comments">comment</i>&nbsp;{featureRequest.totalComments}
      </td>
      <td className="feature-request-line-votes">
        {featureRequest.status >= 5 && <i className="material-icons feature-request-comment-icon" title="Completed">check</i>}
        {featureRequest.status === 0 && <i className="material-icons feature-request-comment-icon" title="New">star</i>}
        {featureRequest.status === 2 && <i className="material-icons feature-request-comment-icon" title="On Roadmap">menu</i>}
        {featureRequest.status === 3 && <i className="material-icons feature-request-comment-icon" title="In Progress">more_horiz</i>}
      </td>
      <td className="feature-request-line-votes">
        {featureRequest.priority === 0 && <i className="material-icons feature-request-comment-icon" title="Low Priority">arrow_downward</i>}
        {featureRequest.priority === 1 && <i className="material-icons feature-request-comment-icon" title="Medium Priority">remove</i>}
        {featureRequest.priority === 2 && <i className="material-icons feature-request-comment-icon" title="High Priority">arrow_upward</i>}
      </td>
    </tr>
  );
}

export default ListFeatureRequestsRow;