import React, { useState } from "react";
import { Prompt } from "react-router-dom";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from 'react-redux'
import Images from "../../../shared/images";
import Errors from "../../../shared/errors";
import {
  renderSelectField,
  renderTextAreaField,
  renderTextField,
  renderReactSelect,
  renderCheckbox,
  renderUrlField,
  renderCurrencyField,
  renderSingleReactSelect
} from "../../../shared/form-helpers";
import required, { maxDecimalValue } from "../../../shared/validation";
import FormErrors from "../../../shared/form-errors";

let CostumeForm = (props) => {
  const {
    handleSubmit,
    handleDelete,
    pristine,
    reset,
    submitting,
    errors,
    acquisitionMethodEnum,
    allCharacters,
    allBackgroundCharacters,
    mode,
    acquisitionMethodValue,
    submitFailed,
    invalid,
    filmId,
    onChangeCostumeToProp
  } = props;
  const thisCostume = props.initialValues || {};
  const readOnly = thisCostume.readOnly;

  const [editUrl, setEditUrl] = useState(false);

  const characterOptions = allCharacters?.map((c) => {
    return { label: c.name, value: c.id };
  });

  const characterSelectedOptions = thisCostume.characters?.map((c) => {
    return characterOptions?.find((x) => x.value === c.id);
  });

  const backgroundCharacterOptions = allBackgroundCharacters?.map((c) => {
    return { label: c.name, value: c.id };
  });

  const backgroundCharacterSelectedOptions = thisCostume.backgroundCharacters?.map((c) => {
    return backgroundCharacterOptions?.find((x) => x.value === c.id);
  });

  const acquisitionMethodOptions = (acquisitionMethodEnum || []).map((item) => { return { value: item.value, label: item.name } });

  return (
    <>
      <Prompt when={!pristine} message="" />
      {mode === "add" && <h2 className="menu-label">Add Costume</h2>}
      {mode === "edit" && <h2 className="menu-label">Edit Costume</h2>}
      <div className="columns">
        <div className="column">
          {errors && <Errors errors={errors} />}
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Field
              name="name"
              label="Name"
              component={renderTextField}
              validate={[required]}
              required
              readOnly={readOnly}
            />

            <Field
              name="characters"
              label="Character"
              component={renderReactSelect}
              options={characterOptions}
              selectedOptions={characterSelectedOptions}
              //validate={[required]}

              readOnly={readOnly}
            />

            <Field
              name="backgroundCharacters"
              label="Background Characters"
              component={renderReactSelect}
              options={backgroundCharacterOptions}
              selectedOptions={backgroundCharacterSelectedOptions}
              //validate={[required]}

              readOnly={readOnly}
            />

            <Field
              name="link"
              label="Link"
              type="url"
              component={renderUrlField}
              readOnly={readOnly}
              setEditUrl={setEditUrl}
              editUrl={editUrl}
            />

            <Field
              name="description"
              label="Description"
              component={renderTextAreaField}
              readOnly={readOnly}></Field>

            <Field
              name="acquisitionMethod"
              label="Acquisition Method"
              component={renderSingleReactSelect}
              options={acquisitionMethodOptions}
              validate={[required]}
              required
              readOnly={readOnly}>
            </Field>

            {acquisitionMethodValue != 3 && acquisitionMethodValue != 4 &&
              <Field
                name="buyBudget"
                label="Budget"
                component={renderCurrencyField}
                validate={[maxDecimalValue]}
                readOnly={readOnly}></Field>
            }
            {acquisitionMethodValue == 3 &&
              <Field
                name="dailyHireBudget"
                label="Daily Budget"
                component={renderCurrencyField}
                validate={[maxDecimalValue]}
                readOnly={readOnly}></Field>
            }

            {acquisitionMethodValue != 4 &&
              <div className="field">
                <Field name="acquired" label="Acquired" component={renderCheckbox} readOnly={readOnly} />
              </div>}
            {!readOnly &&
              <div className="buttons mt-2 sticky-buttons">
                <button
                  type="submit"
                  className="button is-primary"
                  disabled={pristine || submitting}>
                  Save
                </button>
                {mode === "edit" && (
                  <>
                    <button
                      type="button"
                      className="button is-text"
                      disabled={submitting || !props.initialValues}
                      onClick={() => handleDelete(thisCostume)}>
                      Delete Costume
                    </button>
                    <button type="button"
                      className="button is-text"
                      disabled={submitting || !props.initialValues}
                      onClick={() => onChangeCostumeToProp(thisCostume)}>Change to Prop</button>
                  </>
                )}
              </div>}
            <FormErrors submitFailed={submitFailed} invalid={invalid} />

          </form>
        </div>
        <div className="column">
          <Images change={props.change} filmId={filmId} initialValues={props.initialValues} readOnly={readOnly} />
        </div>
      </div>
    </>
  );
}

CostumeForm = reduxForm({
  form: "editCostumeForm",
  enableReinitialize: true
})(CostumeForm);

// Decorate with connect to read form values
const selector = formValueSelector('editCostumeForm'); // <-- same as form name
const CostumeFormConnect = connect(
  state => {
    // can select values individually
    const acquisitionMethodValue = selector(state, 'acquisitionMethod')

    return {
      acquisitionMethodValue
    }
  }
)(CostumeForm)

export default CostumeFormConnect;
