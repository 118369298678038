import { sortByName } from "./../../../shared/utility";
import { combineReducers } from "redux";
import overviewReducer from "./../overview/ducks/reducer";
import scenesReducer from "./../scenes/ducks/reducer";
import mapPictures from "./../../../shared/image-mapper";
import { CHANGE_BACKGROUND_CHARACTER_TO_CHARACTER } from "./actions";
//import { findAllByPlaceholderText } from "@testing-library/react";

const defaultState = {
  backgroundCharacters: [],
  backgroundCharacter: {},
  loading: true,
  readOnly: false,
  loadingRate: false
};

const reducer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case "FETCH_BACKGROUND_CHARACTERS_PENDING": {
      return {
        ...state,
        loading: true
      };
    }

    case "FETCH_BACKGROUND_CHARACTERS_FULFILLED": {
      return {
        ...state,
        backgroundCharacters: action.payload.data.backgroundCharacters,
        readOnly: action.payload.data.readOnly,
        loading: false
      };
    }

    case "FETCH_BACKGROUND_CHARACTERS_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case "FETCH_BACKGROUND_ACTORS_WITH_EMAIL_ADDRESSES_FULFILLED": {
      return {
        ...state,
        backgroundActors: action.payload.data.backgroundActors
      };
    }

    case "FETCH_BACKGROUND_CHARACTER_RATE_PENDING": {
      return {
        ...state,
        loadingRate: true
      };
    }

    case "FETCH_BACKGROUND_CHARACTER_RATE_FULFILLED": {
      const rate = action.payload.data.rate;
      return {
        ...state,
        rate,
        loadingRate: false
      };
    }

    case "FETCH_BACKGROUND_CHARACTER_RATE_REJECTED": {
      return {
        ...state,
        loadingRate: false
      };
    }

    case "INITIALISE_BACKGROUND_CHARACTER": {
      return {
        ...state,
        backgroundCharacter: { ageRange: { min: 0, max: 100 } },
        loading: false
      };
    }

    case "CLEAR_BACKGROUND_CHARACTER": {
      return {
        ...state,
        backgroundCharacter: null,
        errors: null,
        loadingBackgroundCharacter: false
      };
    }

    case "BACKGROUND_CHARACTER_SET_NAME": {
      const backgroundCharacter = { ...state.backgroundCharacter, name: action.meta.name, nameInScript: action.meta.name }
      return {
        ...state,
        backgroundCharacter: backgroundCharacter
      };
    }

    case "BACKGROUND_CHARACTER_GET_GENDER_PENDING": {
      return {
        ...state,
        loading: true,
        errors: null
      };
    }

    case "BACKGROUND_CHARACTER_GET_GENDER_FULFILLED": {
      return {
        ...state,
        loading: false,
        backgroundCharacter: { ...state.backgroundCharacter, gender: action.payload?.data?.gender }
      };
    }

    case "BACKGROUND_CHARACTER_GET_GENDER_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      };
    }

    case "FETCH_SINGLE_BACKGROUND_CHARACTER_PENDING": {
      return {
        ...state,
        backgroundCharacter: null,
        loadingBackgroundCharacter: true
      };
    }

    case "FETCH_SINGLE_BACKGROUND_CHARACTER_FULFILLED": {
      let backgroundCharacter = action.payload.data;
      backgroundCharacter.originalActorId = backgroundCharacter.actorId;

      mapPictures(backgroundCharacter);

      return {
        ...state,
        backgroundCharacter,
        loadingBackgroundCharacter: false,
        errors: null,
        warnings: null
      };
    }

    case "FETCH_SINGLE_BACKGROUND_CHARACTER_REJECTED": {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loadingBackgroundCharacter: false
      };
    }

    case "UPDATE_SINGLE_BACKGROUND_CHARACTER_PENDING": {
      return {
        ...state,
        loadingBackgroundCharacter: true,
        errors: null
      };
    }

    case "UPDATE_SINGLE_BACKGROUND_CHARACTER_FULFILLED": {
      const backgroundCharacter = {
        ...action.meta.backgroundCharacter,
        ...action.payload.data.backgroundCharacter
      };
      const backgroundCharacters: any = state.backgroundCharacters.map((c: any, index: number) => {
        if (c.id === backgroundCharacter.id) {
          return { ...c, ...backgroundCharacter };
        } else {
          return c;
        }
      });

      sortByName(backgroundCharacters);

      let redirect;
      let loadingBackgroundCharacter;
      if (action.meta.redirect) {
        loadingBackgroundCharacter = false;
        redirect = { to: "list" };
      } else {
        loadingBackgroundCharacter = true;
        redirect = null;
      }
      return {
        ...state,
        backgroundCharacter,
        backgroundCharacters,
        loadingBackgroundCharacter: loadingBackgroundCharacter,
        errors: null,
        warnings: action.payload.data.hasWarnings ? action.payload.data.warnings : null,
        redirect: action.payload.data.hasWarnings ? null : redirect
      };
    }

    case "SINGLE_BACKGROUND_CHARACTER_REDIRECT_TO_LIST": {
      return {
        ...state,
        redirect: { to: "list" }
      };
    }

    case "UPDATE_SINGLE_BACKGROUND_CHARACTER_ASSIGN_ACTOR_REJECTED":
    case "UPDATE_SINGLE_BACKGROUND_CHARACTER_UNASSIGN_ACTOR_REJECTED":
    case "UPDATE_SINGLE_BACKGROUND_CHARACTER_REJECTED": {
      return {
        ...state,
        backgroundCharacter: action.meta.backgroundCharacter,
        errors: action.payload.response.data.errors,
        loadingBackgroundCharacter: false
      };
    }

    case "ADD_SINGLE_BACKGROUND_CHARACTER_PENDING": {
      return {
        ...state,
        errors: null,
        warnings: null,
        loadingBackgroundCharacter: true
      };
    }

    case "ADD_SINGLE_BACKGROUND_CHARACTER_FULFILLED": {
      const serverBackgroundCharacter = action.payload.data.backgroundCharacter;
      const backgroundCharacter: any = { ...action.meta.backgroundCharacter, ...serverBackgroundCharacter };

      let backgroundCharacters = [backgroundCharacter, ...state.backgroundCharacters];

      sortByName(backgroundCharacters);

      return {
        ...state,
        backgroundCharacter,
        backgroundCharacters,
        loadingBackgroundCharacter: false,
        errors: null,
        warnings: action.payload.data.hasWarnings ? action.payload.data.warnings : null,
        redirect: { to: "list" }
      };
    }

    case "ADD_SINGLE_BACKGROUND_CHARACTER_REJECTED": {
      return {
        ...state,
        backgroundCharacter: action.meta.backgroundCharacter,
        errors: action.payload.response.data.errors,
        loadingBackgroundCharacter: false
      };
    }

    case "DELETE_SINGLE_BACKGROUND_CHARACTER_PENDING": {
      return {
        ...state,
        backgroundCharacter: action.payload,
        loadingBackgroundCharacter: true,
        errors: null,
        warnings: null
      };
    }

    case "DELETE_SINGLE_BACKGROUND_CHARACTER_FULFILLED": {
      const backgroundCharacter = action.meta.backgroundCharacter;
      const backgroundCharacters: any = state.backgroundCharacters.filter(
        (c: any, index: number) => {
          return c.id !== backgroundCharacter.id;
        }
      );

      return {
        ...state,
        backgroundCharacter: null,
        backgroundCharacters,
        loadingBackgroundCharacter: false,
        errors: null,
        warnings: null,
        redirect: { to: "list" }
      };
    }

    case "DELETE_SINGLE_BACKGROUND_CHARACTER_REJECTED": {
      return {
        ...state,
        backgroundCharacter: action.meta.backgroundCharacter,
        errors: action.payload.response.data.errors,
        loadingBackgroundCharacter: false
      };
    }

    case CHANGE_BACKGROUND_CHARACTER_TO_CHARACTER + "_PENDING": {
      return {
        ...state,
        loadingProp: true,
        errors: null
      };
    }

    case CHANGE_BACKGROUND_CHARACTER_TO_CHARACTER + "_FULFILLED": {
      const backgroundCharacters: any = Array.from(state.backgroundCharacters);
      var index = backgroundCharacters.findIndex(s => s.id === action.meta.backgroundCharacter.id);
      if (index >= 0) {
        backgroundCharacters.splice(index);
      }

      return {
        ...state,
        backgroundCharacters,
        errors: null,
        redirect: { to: "characters", id: action.payload.data.characterId }
      };
    }

    case CHANGE_BACKGROUND_CHARACTER_TO_CHARACTER + "_REJECTED": {
      return {
        ...state,
        character: action.meta.character,
        errors: action.payload.response.data.errors,
        loadingProp: false
      };
    }

    case "CLEAR_REDIRECT": {
      return {
        ...state,
        backgroundCharacter: null,
        redirect: null
      };
    }

    case "UPLOAD_IMAGES_PENDING": {
      return {
        ...state,
        loadingBackgroundCharacter: true
      };
    }

    case "UPLOAD_IMAGES_REJECTED": {
      return {
        ...state,
        loadingBackgroundCharacter: false,
        errors: action.payload.response.data.errors,
      };
    }

    case "UPLOAD_IMAGES_FULFILLED": {
      return {
        ...state,
        loadingBackgroundCharacter: false
      };
    }

    default:
      return state;
  }
};

export default combineReducers({
  rootState: reducer,
  overviewState: overviewReducer,
  scenesState: scenesReducer
});
